import React from 'react';
import Helmet from 'react-helmet';

const Layout = props => {
	function isScriptLoaded(url) {
		if (url) {
			const scripts = document.getElementsByTagName('script');
			for (let i = scripts.length; i--; ) {
				if (scripts[i].src == url) return true;
			}
			return false;
		} else {
			return false;
		}
	}
	if (typeof window !== 'undefined') {
		if (!isScriptLoaded('https://www.dwin1.com/19181.js')) {
			const body = document.getElementsByTagName('body')[0];

			const awMastertag = document.createElement('script');
			awMastertag.setAttribute('defer', 'defer');
			awMastertag.src = 'https://www.dwin1.com/19181.js';
			awMastertag.type = 'text/javascript';

			body.appendChild(awMastertag);
		}
		if (typeof window._iub === 'undefined') {
			const _iub = _iub || [];

			_iub.csConfiguration = {
				lang: 'en',
				siteId: 1807825,
				gdprAppliesGlobally: false,
				consentOnScroll: false,
				whitelabel: false,
				cookiePolicyId: 47439358,
				banner: {
					acceptButtonDisplay: true,
					customizeButtonDisplay: true,
					position: 'float-top-center',
					textColor: 'white',
					backgroundColor: '#333333',
					acceptButtonColor: '#e5014d',
					acceptButtonCaptionColor: 'white',
					customizeButtonColor: '#212121',
					customizeButtonCaptionColor: 'white',
				},
			};
			typeof window !== 'undefined' ? (window._iub = _iub) : null;
		}
	}
	return (
		<React.Fragment>
			<Helmet>
				<meta charSet='utf-8' />
				<link rel='icon' href='/images/favicon.ico' />
				<meta name='viewport' content='width=device-width, initial-scale=1' />
				<meta name='description' content='Travel Medicine Restrictions report provided by DocHQ' />
				<title>DocHQ - Travel Medicine Restrictions</title>
				<link
					href='https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@300;400;500&display=swap'
					rel='stylesheet'
				/>
			</Helmet>
			<div style={{ maxWidth: '100%', overflowX: 'scroll' }}>
				<main style={{ padding: '10px' }}>{props.children}</main>
			</div>
		</React.Fragment>
	);
};

export default Layout;
